<template>
  <div class="project" element-loading-text="loading...">
    <div class="screen">
      <div class="el-dropdown" @click="allFuntion">
        <span class="el-dropdown-link">
          {{ $t("all") }}
        </span>
      </div>
      <el-dropdown @command="categorysFuntion" v-if="categorysData.length > 0">
        <span class="el-dropdown-link">
          {{ $t("category") }}
          <svg-icon iconClass="screen" className="setting"></svg-icon>
        </span>
        <el-dropdown-menu slot="dropdown" class="project-dropdown">
          <el-dropdown-item
            v-for="item in categorysData"
            :key="item.id"
            :command="item.key"
          >
            {{ item.value }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown @command="investorsFuntion" v-if="investorsData.length > 0">
        <span class="el-dropdown-link">
          {{ $t("investors") }}
          <svg-icon iconClass="screen" className="setting"></svg-icon>
        </span>
        <el-dropdown-menu slot="dropdown" class="box-wx-menu project-dropdown">
          <div class="box-list">
            <el-dropdown-item
              v-for="item in investorsData"
              :key="item.id"
              :command="item.key"
              >{{ item.value }}</el-dropdown-item
            >
          </div>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown @command="financing">
        <span class="el-dropdown-link">
          {{ $t("amount") }}
          <svg-icon iconClass="screen" className="setting"></svg-icon>
        </span>
        <el-dropdown-menu slot="dropdown" class="project-dropdown">
          <el-dropdown-item command="million">
            {{ $t("million.value") }}
          </el-dropdown-item>
          <el-dropdown-item command="million1">
            {{ $t("million1.value") }}
          </el-dropdown-item>
          <el-dropdown-item command="million2">
            {{ $t("million2.value") }}
          </el-dropdown-item>
          <el-dropdown-item command="million3">
            {{ $t("million3.value") }}
          </el-dropdown-item>
          <el-dropdown-item command="million4">
            {{ $t("million4.value") }}
          </el-dropdown-item>
          <el-dropdown-item command="million5">
            {{ $t("million5.value") }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{ $t("round") }}
          <svg-icon iconClass="screen" className="setting"></svg-icon>
        </span>
        <el-dropdown-menu slot="dropdown" class="project-dropdown">
          <el-dropdown-item
            v-for="item in $t('financing')"
            :key="item.key"
            :command="item"
          >
            {{ item.value }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-divider></el-divider>
    <!-- 赛选记录 -->
    <div class="record" v-if="createdData.length > 0">
      <div class="reset" @click="Reset()">
        <i class="el-icon-refresh-right"></i> Reset
      </div>
      <div
        class="reset option"
        v-for="(item, index) in createdData"
        :key="index"
      >
        <span class="circle-close" @click="eliminate(index)"></span>
        {{ item.value }}
      </div>
    </div>

    <div class="container" id="wrap" ref="init">
      <ulitem
        :categoryALL="categoryALL"
        :project="true"
        :loading="loading"
        :skeleton-num="8"
      ></ulitem>
      <el-divider class="noMore" v-if="noMore">{{ $t("noMore") }}</el-divider>
    </div>
  </div>
</template>

<script>
import ulitem from "@/components/ulitem";
import { categorys, projectlist, investors } from "@/api/require.js";
export default {
  components: {
    ulitem,
  },
  data() {
    return {
      loading: false,
      categoryALL: [], //数据
      amount: "",
      pageIndex: 1,
      pageSize: 12,
      categorysData: [], //分类
      investorsData: [], //机构
      createdData: [],
      categorysList: [],
      investorsList: [],
      pointer: true,
      gross: true,
      orderBy: "",
      sort: "asc",
      scroll: 0,
      noMore: false,
      dataobj: {
        amount: {
          //融资金额范围
          end: 10000000000, //结束值
          start: 0, //开始值
        },
        category: [
          //需要匹配的分类列表
        ],
        investors: [
          //投资机构
        ],
        key: "", // 项目名称关键字
        pageIndex: 1,
        pageSize: 24,
        // round: this.vessel // 融资轮
      },
    };
  },
  created() {
    this.categorys();
    // 项目列表
    this.projectlist();
    // 投资机构
    this.investors();

    // 接收全局广播事件
    this.Event.$on("data-search", (res) => {
      this.dataobj.key = res;
      this.dataobj.pageIndex = 1;
      this.categoryALL = [];
      this.projectlist();
    });
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.handleScroll, true);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    async projectlist() {
      this.loading = true;
      let res = await projectlist(this.dataobj);
      if (res.code === 0) {
        // 把时间格式转为英文
        // console.log('把时间格式转为英文')
        if (res.data.list.length < 1) {
          this.loading = false;
          this.noMore = true;
          return;
        }
        res.data.list.forEach((item) => {
          item["enchangeTime"] = this.$tool.conversion(item.changeTime);
        });
        this.categoryALL.push(...res.data.list);
      }
      this.loading = false;
    },

    async investors() {
      let res = await investors();

      if (res.code === 0) {
        if (res.data.length > 0) {
          this.investorsData = res.data;
        }
      }
    },

    async categorys() {
      let res = await categorys();
      if (res.code === 0) {
        if (res.data.length > 0) {
          this.categorysData = res.data;
        }
      }
    },

    eliminate(index) {
      if (this.createdData[index].key == 1) {
        this.pointer = true;
        try {
          delete this.dataobj.round;
        } catch (error) {
          console.log(error);
        }
      } else if (this.createdData[index].key == 2) {
        this.gross = true;
        // 初始化
        try {
          this.dataobj.amount.start = 0;
          this.dataobj.amount.end = 10000000000;
        } catch (error) {
          console.log(error);
        }
      }

      // 在删除投资机构参数里面的
      this.investorsList.forEach((item, num) => {
        if (this.createdData[index].key == item) {
          this.investorsList.splice(num, 1);
        }
      });
      // 在删除分类参数里面的
      this.categorysList.forEach((item, num) => {
        if (this.createdData[index].key == item) {
          this.categorysList.splice(num, 1);
        }
      });

      this.createdData.splice(index, 1);

      // 调一次接口
      // 项目列表
      this.dataobj.pageIndex = 1;
      this.categoryALL = [];
      this.projectlist();
    },

    handleScroll() {
      let offsetHeight = this.$refs.init.offsetHeight; //窗口可视高度
      let innerHeight = document.querySelector("#ulitem").offsetHeight; //元素的高度
      let scrollTop = document.querySelector("#wrap").scrollTop; //滚动条滚动距离
      let bottomOfWindow = scrollTop + offsetHeight >= innerHeight;
      if (scrollTop - this.scroll < 0) {
        return;
      }
      this.scroll = scrollTop;
      // console.log("滑动到最底部",bottomOfWindow)
      if (bottomOfWindow && !this.loading) {
        //用户下拉触底
        setTimeout(() => {
          this.dataobj.pageIndex += 1;
          this.loading = true;
          this.projectlist();
        }, 500);
      }
    },

    Reset() {
      this.dataobj = {
        amount: {
          //融资金额范围
          end: 10000000000, //结束值
          start: 0, //开始值
        },
        category: [
          //需要匹配的分类列表
        ],
        investors: [
          //投资机构
        ],
        key: "", // 项目名称关键字
        pageIndex: 1,
        pageSize: 24,
        // round: this.vessel // 融资轮
      };
      (this.createdData = []),
        (this.categorysList = []),
        (this.investorsList = []),
        (this.categoryALL = []);
      this.projectlist();
    },
    allFuntion(command) {
      this.Reset();
      this.projectlist();
    },
    // 机构
    investorsFuntion(command) {
      this.public(false, command);
    },
    // 分类
    categorysFuntion(command) {
      this.public(true, command);
    },
    // 融资轮
    handleCommand(command) {
      this.communal(1, command);
    },

    // 融资总量
    financing(command) {
      this.communal(2, { value: this.$t(command).value });

      // 掉一次接口
      this.dataobj.amount.start = this.$t(command).key.start;
      this.dataobj.amount.end = this.$t(command).key.end;

      // 项目列表
      this.dataobj.pageIndex = 1;
      this.categoryALL = [];
      this.projectlist();
    },

    communal(key, command) {
      let obj = {
        key: key,
        value: command.value,
      };

      if (this.createdData.length > 0) {
        let arry = this.createdData;
        arry.forEach((item, index) => {
          if (item.key == obj.key) {
            this.createdData[index].value = command.value;
          } else {
            // 融资轮次
            if (key == 1) {
              if (this.pointer) {
                this.pointer = false;
                let data = [...this.createdData, obj];
                this.createdData = data;
                // break;
              }
            } else if (key == 2) {
              // 融资总量
              if (this.gross) {
                this.gross = false;
                let data = [...this.createdData, obj];
                this.createdData = data;
                // break;
              }
            }
          }
        });
      } else {
        let data = [...this.createdData, obj];
        this.createdData = data;
      }

      if (key == 1) {
        // 添加融资轮查询
        this.dataobj["round"] = command.key;
        this.dataobj.pageIndex = 1;
        this.categoryALL = [];
        // 项目列表
        this.projectlist();
      }

      this.createdData = this.createdData.filter(function (
        element,
        index,
        self
      ) {
        return self.findIndex((el) => el.key == element.key) === index;
      });
    },

    public(val, command) {
      let obj = {
        key: command,
        value: command,
      };

      let data = [...this.createdData, obj];
      var arr1 = data.filter(function (element, index, self) {
        return self.findIndex((el) => el.key == element.key) === index;
      });
      if (val) {
        this.categorysList.push(command);
        this.categorysList = [...new Set(this.categorysList)];
        this.dataobj.category = this.categorysList;
      } else {
        this.investorsList.push(command);
        this.investorsList = [...new Set(this.investorsList)];
        this.dataobj.investors = this.investorsList;
      }
      // 显示出来的
      this.createdData = arr1;

      // 项目列表
      this.dataobj.pageIndex = 1;
      this.categoryALL = [];
      this.projectlist();
    },
  },
};
</script>

<style lang="less" scoped>
.project {
  /deep/.el-loading-mask {
    background-color: rgba(0, 0, 0, 0.2);
  }
  /deep/.noMore {
    .el-divider__text {
      background: transparent;
      color: #7a8087;
    }
  }
  .container {
    margin-top: 30px;
    height: calc(100vh - 240px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      background: #fff;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #26e7ae;
    }
    &::-o-scrollbar {
      width: 4px;
      background: #fff;
      border-radius: 20px;
    }
    &::-o-scrollbar-thumb {
      border-radius: 10px;
      background: #26e7ae;
    }
    &::-ms-scrollbar {
      width: 4px;
      background: #fff;
      border-radius: 20px;
    }
    &::-ms-scrollbar-thumb {
      border-radius: 10px;
      background: #26e7ae;
    }
    scrollbar-color: #26e7ae #fff; //设置滚动条颜色
    scrollbar-width: thin;
  }
  .record {
    display: flex;
    .reset {
      cursor: pointer;
      margin-right: 10px;
      height: 24px;
      padding: 1px 20px;
      background: #1fe0a7;
      border-radius: 20px;
      line-height: 26px;
      color: #13154d;
      text-align: center;
      font-size: 14px;
    }
    .option {
      background: #175268;
      position: relative;
      color: #1fe0a7;
      .circle-close {
        width: 15px;
        height: 15px;
        display: block;
        cursor: pointer;
        position: absolute;
        background: url("../../static/images/19.png") 100% center/100% no-repeat;
        right: 0px;
        top: -8px;
      }
    }
  }
  .screen {
    .el-dropdown {
      cursor: pointer;
      margin-right: 30px;
      color: #909399;
      .el-dropdown-link {
        display: flex;
        align-items: center;
      }
      .setting {
        font-size: 16px;
        color: #909399;
        // background: url("../../static/images/2.png") 100% center/100% no-repeat;
      }
    }
  }
  .el-divider {
    background: #30343c;
  }
}
.isMobile {
  .project .container {
    height: calc(100vh - 255px);
  }
  .screen {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-dropdown {
      margin: 0 20px 8px 0;
    }
  }

  .project .record {
    flex-wrap: wrap;
  }
  .project .record .reset {
    margin-bottom: 10px;
  }
}
</style>
